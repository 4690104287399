import * as React from 'react';
import { IGroupHomeValues } from '../../Services/API/Group/GroupHomeValues';
import Wootric from '../../Components/Wootric';
import Intercom from '../../Components/Intercom';
import Clarity from '../../Components/Clarity';

interface IGroupViewContextProvider {
    children: JSX.Element[];
}

export interface IGroupValues {
    i_zoneNo?: number;
    nv_unitCounty?: string;
    numSitesCapex?: number;
    moduleView?: number;
    description?: string;
    unitCount?: number;
    defaultGroup?: number;
    viewType?: string;
    uid_viewType?: number;
    uid_unit3DCachedSQL?: number;
    groupViewHomePageValues?: IGroupHomeValues;
    b_lumpCap?: string;
    b_lumpPMV?: string;
    b_lumpRep?: string;
    b_recutCapital?: string;
    b_recutRepair?: string;
    b_showRecut?: string;
    nv_search?: string;
}

const GroupViewContextProvider = (props: IGroupViewContextProvider) => {
    const [value, setValue] = React.useState<IGroupValues>({});
    const [defaultValue, setDefaultValue] = React.useState<IGroupValues>();

    const setContextValue = (val: IGroupValues) => {
        setValue(val);
    };
    const setDefaultContextValue = (val: IGroupValues) => {
        if (!defaultValue) {
            setDefaultValue(val);
        }
    };

    const clearContextValue = () => {
        setValue({});
    };

    return (
        <GroupViewContext.Provider
            value={{
                value,
                setValue: setContextValue,
                clearValue: clearContextValue,
                setDefault: setDefaultContextValue,
                defaultValue,
            }}
        >
            <Intercom />
            <Wootric />
            <Clarity />
            {props.children}
        </GroupViewContext.Provider>
    );
};

export default GroupViewContextProvider;

export interface IGroupViewContext {
    value?: IGroupValues;
    setValue?: (val: IGroupValues) => void;
    clearValue?: () => void;
    setDefault?: (val: IGroupValues) => void;
    defaultValue?: IGroupValues;
}

export const GroupViewContext = React.createContext<IGroupViewContext>({});
