import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, RouteComponentProps } from 'react-router-dom';
import qs from 'qs';

// Our Global imports:
import './Helpers/global';
import GroupViewContextProvider from './Scenes/Group/GroupViewContext';
import { TagProgressIndeterminate, TagModal } from '@tag/tag-components-react-v2';
import { IAMTransactionEvent, IBaseUrlMessage } from './Services/API/Loader';
import { unlockallAsync } from './Services/API/UnlockAllOrderService';
import withContCheck from './Scenes/ContractorHO/withContCheck';
import { ISignInURLProps } from './Scenes/SignIn/SignInView';
import { ISignOutURLProps } from './Scenes/SignOut/SignOutView';
import { IWorkspaceMigrationHomeURLProps } from './Scenes/WorkspaceMigration/WorkspaceMigrationHome';
import { IWorkspaceMigrationErrorURLProps } from './Scenes/WorkspaceMigration/WorkspaceMigrationError';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

interface IState {
    AMTransactionEvent?: IAMTransactionEvent;
    BaseUrl?: string;
}

const HeadOfficeView = lazy(() => import('./Scenes/HeadOfficeHome/HeadOfficeView'));
const UnitView = lazy(() => import('./Scenes/Unit/UnitView'));
const GroupView = lazy(() => import('./Scenes/Group/GroupView'));
const AdministrationView = lazy(() => import('./Scenes/Administration/AdministrationView'));
const FinancialView = lazy(() => import('./Scenes/Financial/FinancialView'));
const ProjectsView = lazy(() => import('./Scenes/Projects/ProjectsView'));
const ConfigurationView = lazy(() => import('./Scenes/Configuration/ConfigurationView'));
const CheckUserType = lazy(() => import('./Scenes/ContractorHO/CheckUserType'));
const ApplicationPackView = lazy(() => import('./Scenes/ApplicationPack/ApplicationPackView'));
const SignInView = lazy(() => import('./Scenes/SignIn/SignInView'));
const MaintenanceView = lazy(() => import('./Scenes/Maintenance/MaintenanceView'));
const SignOut = lazy(() => import('./Scenes/SignOut/SignOutView'));
const WorkspaceMigrationHome = lazy(
    () => import('./Scenes/WorkspaceMigration/WorkspaceMigrationHome'),
);
const WorkspaceMigrationError = lazy(
    () => import('./Scenes/WorkspaceMigration/WorkspaceMigrationError'),
);

// Wrap in functions to remove error
const RenderHeadOfficeView = () => <HeadOfficeView />; // 1D
const RenderUnitView = () => <UnitView />; // 2D
const RenderGroupView = () => <GroupView />; // 3D
const RenderAdministrationView = () => <AdministrationView />; // 5D
const RenderFinancialView = () => <FinancialView />; // 6D
const RenderProjectsView = () => <ProjectsView />; // Projects/Capex
const RenderConfigurationView = () => <ConfigurationView />; // Configuration
const RenderApplicationPackView = () => <ApplicationPackView />; // Application pack
const RenderMaintenanceView = () => <MaintenanceView />; // Maintenance View
const RenderSignInView = ({ match, history, location }: RouteComponentProps<ISignInURLProps>) => (
    <SignInView match={match} history={history} location={location} />
); // Sign in view
const RenderSignOutView = ({ match, history, location }: RouteComponentProps<ISignOutURLProps>) => (
    <SignOut match={match} history={history} location={location} />
); // Sign in view
const RenderWorkspaceMigrationHomeView = ({
    match,
    history,
    location,
}: RouteComponentProps<IWorkspaceMigrationHomeURLProps>) => (
    <WorkspaceMigrationHome match={match} history={history} location={location} />
);
const RenderWorkspaceMigrationErrorView = ({
    match,
    history,
    location,
}: RouteComponentProps<IWorkspaceMigrationErrorURLProps>) => (
    <WorkspaceMigrationError match={match} history={history} location={location} />
);

class App extends React.Component<Record<string, never>, IState> {
    constructor(props: Record<string, never>) {
        super(props);
        window.addEventListener(
            'AM_MainTransactionEvent',
            (e: CustomEvent<IAMTransactionEvent>) => {
                this.setState({ AMTransactionEvent: e.detail });
                window.newrelic?.noticeError(new Error(e.detail.description), e.detail);
            },
        );
        window.addEventListener('message', (e: MessageEvent<IBaseUrlMessage>) => {
            if (e && e.data.type === 'SetBaseLocation') {
                this.setState({ BaseUrl: e.data.url });
                window.maintain_baseUrl = e.data.url;
            }
        });
        this.state = {};
        this.getLocale();
    }

    public handleSignOut(userName: string | undefined, dimension: string | undefined) {
        unlockallAsync();

        const params = qs.parse(window.location.search, { ignoreQueryPrefix: true }) as Record<
            string,
            unknown
        >;
        const redirectParams = Object.prototype.hasOwnProperty.call(params, 'b_workspace')
            ? `${window.location.search}&nv_Email=${userName}&nv_dimension=${dimension}`
            : '';

        window.parent.location.href = `../../end.asp${redirectParams}`;
    }

    public getBaseURL() {
        const message = {
            type: 'GetBaseLocation',
        };
        window.parent.postMessage(JSON.stringify(message), '*');
    }

    public waitForFrame() {
        const timer = () =>
            setTimeout(() => {
                this.componentDidMount();
            }, 1000);
        const timerId = timer();
        return () => {
            clearTimeout(timerId);
        };
    }
    public async getLocale() {
        const locale = process.env.NODE_ENV === 'development' ? 'pseudo-en' : 'en'; // makes sure that we are in pseudo-en in development
        const { messages } = await import(`./locales/${locale}.ts`);
        i18n.loadAndActivate({ locale, messages });
    }

    public componentDidMount() {
        const isWorkspace = window.location.href.toLowerCase().indexOf('b_workspace=1') > -1;
        const loc = window.location.href
            .toLowerCase()
            .substring(0, window.location.href.toLowerCase().indexOf('pronett/', 8) + 7);
        const isStatic = window.location.href.toLowerCase().indexOf('static') > -1;

        if (isWorkspace) {
            if (this.state.BaseUrl === undefined && isStatic) {
                if (this.state.BaseUrl === undefined) {
                    this.waitForFrame();
                    this.getBaseURL();
                }
            } else if (this.state.BaseUrl === undefined && !isStatic) {
                this.setState({ BaseUrl: loc });
                window.maintain_baseUrl = loc;
            }
        } else {
            this.setState({ BaseUrl: undefined });
            window.maintain_baseUrl = undefined;
        }

        return this.renderContent();
    }

    public render() {
        return this.renderContent();
    }

    public renderContent() {
        return (
            <div>
                <Router basename={process.env.PUBLIC_URL || '/ProNett/AM/'}>
                    <div>
                        <Suspense
                            fallback={<TagProgressIndeterminate text="Loading" full={true} />}
                        >
                            <>
                                <Switch>
                                    <Route
                                        exact={true}
                                        path="/workspaceMigrationError"
                                        component={RenderWorkspaceMigrationErrorView}
                                    />
                                    <Route
                                        exact={true}
                                        path="/maintenanceView"
                                        component={RenderMaintenanceView}
                                    />
                                    <Route
                                        exact={true}
                                        path="/workspaceMigration"
                                        component={RenderWorkspaceMigrationHomeView}
                                    />
                                    <Route
                                        exact={true}
                                        path="/signin"
                                        component={RenderSignInView}
                                    />
                                    <Route exact={true} path="/end" component={RenderSignOutView} />
                                    <I18nProvider i18n={i18n}>
                                        <GroupViewContextProvider>
                                            <Route
                                                exact={true}
                                                path="/contractor"
                                                component={withContCheck(CheckUserType)}
                                            />
                                            <Route
                                                exact={true}
                                                path="/2D"
                                                component={RenderUnitView}
                                            />
                                            <Route
                                                exact={true}
                                                path="/6D"
                                                component={RenderFinancialView}
                                            />
                                            <Route
                                                exact={true}
                                                path="/1d"
                                                component={RenderHeadOfficeView}
                                            />
                                            <Route
                                                exact={true}
                                                path="/3D"
                                                component={RenderGroupView}
                                            />
                                            <Route
                                                exact={true}
                                                path="/5D"
                                                component={RenderAdministrationView}
                                            />
                                            <Route
                                                exact={true}
                                                path="/application"
                                                component={RenderApplicationPackView}
                                            />
                                            <Route
                                                exact={true}
                                                path="/Projects"
                                                component={RenderProjectsView}
                                            />
                                            <Route
                                                exact={true}
                                                path="/Configuration"
                                                component={RenderConfigurationView}
                                            />
                                        </GroupViewContextProvider>
                                    </I18nProvider>
                                </Switch>
                            </>
                        </Suspense>
                    </div>
                </Router>

                {this.state.AMTransactionEvent ? (
                    <>
                        <TagModal
                            visible
                            heading="Information"
                            headingIcon="CircleInfo"
                            headingIconAccent="info"
                            headingType="span"
                            headingBackgroundAccent="white"
                            backgroundAccent="white"
                            borderAccent="info"
                            headingAccent="title"
                            width="small"
                            primaryButton="Confirm"
                            primaryButtonAccent="info"
                            headingStyle={{ fontSize: '15px', fontWeight: 700 }}
                            headingContainerStyle={{ borderBottom: 'none' }}
                            onClosed={this.dealWithTransactionError}
                        >
                            {this.state.AMTransactionEvent.description
                                .split('<br/>')
                                .map((item, index) => (
                                    <div key={index}>{item}</div>
                                ))}
                        </TagModal>
                    </>
                ) : null}
            </div>
        );
    }

    private readonly dealWithTransactionError = () => {
        if (this.state.AMTransactionEvent) {
            if (this.state.AMTransactionEvent.action === 'logout') {
                this.handleSignOut(
                    this.state.AMTransactionEvent.userName,
                    this.state.AMTransactionEvent.dimension,
                );
            } else {
                window.location.reload();
            }
        }
    };
}

export default App;
