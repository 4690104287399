import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import '@tag/tag-components';
import { defineCustomElements } from '@tag/tag-components';

import App from './App';
import { unregister } from './registerServiceWorker';

import '@tag/tag-components/dist/tag-library.css';
import './index.scss';
import 'bootstrap/scss/bootstrap.scss';
import './Shared/offsetParent-polyfill';

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);
defineCustomElements(window);
// registerServiceWorker();
unregister();
