import { Get, IDefaultResponse } from './Loader';

export interface IGlobalValuesDataRow extends IDefaultResponse {
    nv_clientName: string;
    username: string;
    currencySymbol: string;
    nv_todaysDate: Date;
    nv_mapSuffix: string;
    nv_FullNameGroup: string;
    nv_FullName: string;
    b_view7D: boolean;
    b_viewContractorModule: boolean;
    dt_userEnabled: string;
    b_isDemoOrDevSystem: boolean;
    blackListedDomains: string[];
    wootricNewDate: Date;
    nv_ApmId?: string;
    nv_IntercomAppId: string;
    nv_APIBaseURL: string;
    IntercomUserHash: string;
    IntercomCompanyID: string;
    nv_UserType: string;
    SubscriptionUID?: string;
    b_NonZone: boolean;
    nv_systemLanguageCode: string;
}

const getGlobalValues = () => {
    async function load() {
        const data = Get({
            name: 'GetGlobalValues',
            URL: '../Common/ASP/getGlobalValues.asp',
        });
        await data;
        return data.then((response: { data: IGlobalValuesDataRow } | undefined) => {
            return response ? (response.data as IGlobalValuesDataRow) : undefined;
        });
    }

    return load();
};

export default getGlobalValues;
