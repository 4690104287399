import { Get } from './Loader';

interface IUnlockAll {
    params?: { [x: string]: unknown };
    name: string;
}

export const unlockallSync = (props: IUnlockAll) => {
    function unlockSync() {
        if (!props.params) {
            props.params = {};
        }
        props.params.flash_id = Math.round(Math.random() * 100000000);
        props.params.flash_an = 1;
        props.params.flash_swf = 'ReTAG-' + props.name;
        props.params.flash_uid = window.flash_uid;
        props.params.lStart = props.params.lStart || 0;
        props.params.lNum = props.params.lNum || -1;

        const url =
            `../../common/ASP/unlockAll.asp?flash_id=${props.params.flash_id}&flash_an=${props.params.flash_an}` +
            `&flash_swf=${props.params.flash_swf}&flash_uid=${props.params.flash_uid}&lStart=${props.params.lStart}&lNum=${props.params.lNum}`;

        try {
            if (window.fetch) {
                window.fetch(url, { keepalive: true, method: 'GET' }).catch((reason) => {
                    // eslint-disable-next-line no-console
                    console.error('Failed to unlock');
                });
            } else if (navigator.sendBeacon) {
                navigator.sendBeacon(url);
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Failed to unlock');
        }
    }
    return unlockSync;
};

export const unlockallAsync = () => {
    async function unlockall() {
        const data = Get({
            name: 'UnlockAllInvoice',
            URL: '../common/ASP/unlockAll.asp',
            method: 'post',
            responseType: 'text',
        });
        await data;
        return true;
    }
    return unlockall;
};
