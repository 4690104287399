import { useEffect } from 'react';
import getGlobalValues from '../../Services/API/GetGlobalValues';

interface IWindow extends Window {
    clarity: (command: string, ...args: string[]) => void;
}

const customWindow = window as unknown as IWindow;

const Clarity = () => {
    useEffect(() => {
        const fetchGlobalValues = async () => {
            const globalValues = await getGlobalValues();
            if (customWindow.clarity && globalValues) {
                customWindow.clarity('set', 'UserType', globalValues.nv_UserType);
            }
        };

        fetchGlobalValues();
    }, []);
    return null;
};

export default Clarity;
