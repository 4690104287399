import React from 'react';
import getGlobalValues from '../../Services/API/GetGlobalValues';

interface IWithContCheckState {
    contUserCheck: {
        b_view7D?: boolean;
        b_viewContractorModule?: boolean;
        username?: string;
    };
}

export default function withContCheck<T>(WrappedComponent: React.ComponentType<T>) {
    return class ContCheck extends React.Component<Record<string, never>, IWithContCheckState> {
        constructor(props: Record<string, never>) {
            super(props);
            this.state = {
                contUserCheck: {},
            };
        }

        public componentDidMount() {
            const getCheck = async () => {
                const res = await getGlobalValues();
                if (res) {
                    this.setState({
                        contUserCheck: {
                            b_view7D: res.b_view7D,
                            b_viewContractorModule: res.b_viewContractorModule,
                            username: res.username,
                        },
                    });
                }
            };
            getCheck();
        }

        public render() {
            return (
                <WrappedComponent contUserCheck={this.state.contUserCheck} {...(this.props as T)} />
            );
        }
    };
}
