import React, { useEffect, useState } from 'react';
import getGlobalValues, { IGlobalValuesDataRow } from '../../Services/API/GetGlobalValues';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const beaconId = '977b14c5-68d8-46a1-83aa-e3fe38782cde';
const scriptNonce = '9c971919-d548-4980-b1a3-0c2b09ee0b63';
const excludedPaths = [`/application/`];

const setupBeacon = (script: HTMLScriptElement) => {
    script.type = 'text/javascript';
    script.async = true;
    script.onload = handleOnLoad;
    document.body.appendChild(script);
};
const startIntercom = () => {
    const win = window;
    if (win.Intercom) {
        win.Intercom('show');
    }
};

const shutDownIntercom = () => {
    const win = window;
    if (win.Intercom) {
        win.Intercom('shutdown');
    }
};
const handleOnLoad = () => {
    startIntercom();
};
export const Intercom = (props: RouteComponentProps) => {
    const [IntercomEnabled, setIntercomEnabled] = useState(false);

    useEffect(() => {
        window.addEventListener('unload', shutDownIntercom);
        return () => {
            window.removeEventListener('unload', shutDownIntercom);
        };
    }, []);

    useEffect(() => {
        if (excludedPaths.indexOf(props.location.pathname.toLowerCase()) === -1) {
            setIntercomEnabled(true);
        } else {
            setIntercomEnabled(false);
        }
    }, [props.location.pathname]);

    useEffect(() => {
        if (!IntercomEnabled) {
            shutDownIntercom();
            return;
        }
        getGlobalValues().then((values) => {
            if (values) {
                const setupNode = document.createElement('script');
                setupNode.classList.add('Intercom');
                setupNode.setAttribute('id', scriptNonce);
                setupNode.setAttribute('nonce', scriptNonce);
                setupIntercomOptions(setupNode, values);
                document.body.appendChild(setupNode);

                // Beacon
                const beaconNode = document.createElement('script');
                beaconNode.setAttribute('id', beaconId);
                beaconNode.setAttribute('nonce', beaconId);
                beaconNode.classList.add('Intercom');
                setupBeacon(beaconNode);
            }
        });

        return () => {
            shutDownIntercom();
        };
    }, [IntercomEnabled]);

    const setupIntercomOptions = (
        script: HTMLScriptElement,
        globalValues: IGlobalValuesDataRow,
    ) => {
        const userEnabledDate = new Date(globalValues.dt_userEnabled);
        const timestamp = Math.floor(userEnabledDate.getTime() / 1000);
        if (script) {
            window.intercomSettings = {
                api_base: globalValues.nv_APIBaseURL,
                app_id: globalValues.nv_IntercomAppId,
                name: globalValues.nv_FullName,
                email: globalValues.username.includes('@') ? globalValues.username : undefined,
                user_id: globalValues.nv_ApmId,
                created_at: timestamp,
                user_hash: globalValues.IntercomUserHash,
                user_type: globalValues.nv_UserType,
                company: {
                    id: globalValues.IntercomCompanyID,
                    name: globalValues.nv_clientName,
                },
            };
            script.innerHTML = `
                (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${globalValues.nv_IntercomAppId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
              `;
        }
    };

    return <div />;
};

export default withRouter(Intercom);
