/* eslint-disable eqeqeq */
/* eslint-disable no-console */
// This polyfill is required until a solution is found to the popover issue in TAG 1.7.x
// based on https://raw.githubusercontent.com/josepharhar/offsetparent-polyfills/main/offsetParent-polyfill.js

(() => {
    type offsetParentType = () => typeof HTMLElement.prototype.offsetParent; // right now the type is Element | null
    type offsetTopLeftType = () =>
        | typeof HTMLElement.prototype.offsetTop
        | typeof HTMLElement.prototype.offsetLeft; // type is number
    type potentialNodeTypes = ParentNode | Element | HTMLSlotElement;

    const originalOffsetParent = Object.getOwnPropertyDescriptor(
        HTMLElement.prototype,
        'offsetParent',
    )?.get as offsetParentType;
    const originalOffsetTop = Object.getOwnPropertyDescriptor(HTMLElement.prototype, 'offsetTop')
        ?.get as offsetTopLeftType;
    const originalOffsetLeft = Object.getOwnPropertyDescriptor(HTMLElement.prototype, 'offsetLeft')
        ?.get as offsetTopLeftType;

    function flatTreeParent(element: potentialNodeTypes): null | potentialNodeTypes {
        // This function won't correctly infer the return types
        if ('assignedSlot' in element && element.assignedSlot) {
            return element.assignedSlot;
        }
        if (element.parentNode instanceof ShadowRoot) {
            return element.parentNode.host;
        }
        return element.parentNode;
    }

    function ancestorTreeScopes(element: Element) {
        const scopes = new Set();
        let currentScope: Node | null = element.getRootNode();
        while (currentScope) {
            scopes.add(currentScope);
            currentScope = currentScope.parentNode ? currentScope.parentNode.getRootNode() : null;
        }
        return scopes;
    }

    function offsetParentPolyfill(element: Element, isNewBehavior: boolean) {
        // Do an initial walk to check for display:none ancestors.
        for (
            let ancestor: potentialNodeTypes | null = element;
            ancestor;
            ancestor = flatTreeParent(ancestor)
        ) {
            if (!(ancestor instanceof Element)) {
                continue;
            }
            if (getComputedStyle(ancestor).display === 'none') {
                return null;
            }
        }

        let scopes = null;
        if (isNewBehavior) {
            scopes = ancestorTreeScopes(element);
        }

        for (
            let ancestor = flatTreeParent(element);
            ancestor;
            ancestor = flatTreeParent(ancestor)
        ) {
            if (!(ancestor instanceof Element)) {
                continue;
            }
            const style = getComputedStyle(ancestor);
            // display:contents nodes aren't in the layout tree so they should be skipped.
            if (style.display === 'contents') {
                continue;
            }
            if (style.position !== 'static' || style.filter !== 'none') {
                if (isNewBehavior) {
                    if (scopes?.has(ancestor.getRootNode())) {
                        return ancestor;
                    }
                } else {
                    return ancestor;
                }
            }
            if (ancestor.tagName === 'BODY') {
                return ancestor;
            }
        }
        return null;
    }

    let isOffsetParentPatchedCached: boolean | null = null;
    function isOffsetParentPatched() {
        if (isOffsetParentPatchedCached !== null) {
            return isOffsetParentPatchedCached;
        }

        const container = document.createElement('div');
        container.style.position = 'absolute';
        const shadowRoot = container.attachShadow({ mode: 'open' });
        document.body.appendChild(container);

        const lightChild = document.createElement('div');
        container.appendChild(lightChild);

        const shadowChild = document.createElement('div');
        shadowChild.style.position = 'absolute';
        shadowChild.appendChild(document.createElement('slot'));
        shadowRoot.appendChild(shadowChild);

        const originalValue = originalOffsetParent.apply(lightChild);
        if (originalValue == container) {
            isOffsetParentPatchedCached = true;
        } else if (originalValue == shadowChild) {
            isOffsetParentPatchedCached = false;
        } else {
            console.error('what ', originalValue);
        }

        container.remove();
        return isOffsetParentPatchedCached;
    }

    function offsetTopLeftPolyfill(element: HTMLElement, originalFn: offsetTopLeftType) {
        if (!isOffsetParentPatched()) {
            return originalFn.apply(element) as number;
        }

        let value = originalFn.apply(element) as number;
        let nextOffsetParent = offsetParentPolyfill(element, /*isNewBehavior=*/ false);
        const scopes = ancestorTreeScopes(element);

        while (nextOffsetParent && !scopes.has(nextOffsetParent.getRootNode())) {
            value -= originalFn.apply(nextOffsetParent);
            nextOffsetParent = offsetParentPolyfill(nextOffsetParent, /*isNewBehavior=*/ false);
        }

        return value;
    }

    Object.defineProperty(HTMLElement.prototype, 'offsetParent', {
        get() {
            return offsetParentPolyfill(this, /*isNewBehavior=*/ false);
        },
    });

    Object.defineProperty(HTMLElement.prototype, 'offsetTop', {
        get() {
            return offsetTopLeftPolyfill(this, originalOffsetTop);
        },
    });

    Object.defineProperty(HTMLElement.prototype, 'offsetLeft', {
        get() {
            return offsetTopLeftPolyfill(this, originalOffsetLeft);
        },
    });
})();

export {};
