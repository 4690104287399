import React, { useEffect, useState } from 'react';
import getGlobalValues, { IGlobalValuesDataRow } from '../../Services/API/GetGlobalValues';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const beaconId = 'b8d77712-70f0-4a38-b8ee-2eb38fffdbce';
const optionsId = '769cad61-0f3f-4a96-9e80-5c7a7ab1f003';

const newAccountToken = 'NPS-9acab375';
const testAccountToken = 'NPS-8a385e4e';
const excludedPaths = [`/contractor/`, `/application/`];

let currentDate = new Date();

export const Wootric = (props: RouteComponentProps) => {
    const [wootricEnabled, setWootricEnabled] = useState(false);
    useEffect(() => {
        if (excludedPaths.indexOf(props.location.pathname.toLowerCase()) === -1) {
            setWootricEnabled(true);
        } else {
            setWootricEnabled(false);
        }
    }, [props.location.pathname]);
    useEffect(() => {
        if (!wootricEnabled) {
            stopWootric();
            return;
        }
        getGlobalValues().then((values) => {
            if (values) {
                currentDate = new Date(values.nv_todaysDate);
                const setupNode = document.createElement('script');
                setupNode.classList.add('wootric');
                setupNode.setAttribute('id', optionsId);
                setupNode.setAttribute('nonce', optionsId);
                setupWootricOptions(setupNode, values);
                document.body.appendChild(setupNode);

                const blackListedDomainsFallBack = [
                    'pronett.com',
                    'pronett.co.uk',
                    'theaccessgroup.com',
                    'isoftwaresystems.com',
                    'designmynight.com',
                    'firstchoice.org.uk',
                    'hcsseducation.co.uk',
                    'access-accounts.com',
                    'teamseer.com',
                    'thankq.co.uk',
                    'attachesoftware.com',
                    'stratogen.net',
                    'deltasoftware.co.uk',
                    'stratogen.com',
                    'dhcltd.com',
                    'accountforcarbon.com',
                    'accessnet.co.uk',
                    'rapidata.co.uk',
                    'volcanic.co.uk',
                    'changeworknow.co.uk',
                    'accountingforcarbon.com',
                    'prelytis.fr',
                ];
                let blackListedDomains = [];
                if (values.blackListedDomains) {
                    blackListedDomains = values.blackListedDomains;
                } else {
                    blackListedDomains = blackListedDomainsFallBack;
                }

                let emailBlackListed = false;
                blackListedDomains.forEach((item) => {
                    if (values.username.toLocaleLowerCase().indexOf(item.toLowerCase()) > -1) {
                        emailBlackListed = true;
                    }
                });

                if (!emailBlackListed || currentDate < new Date(values.wootricNewDate)) {
                    // Beacon
                    const beaconNode = document.createElement('script');
                    beaconNode.setAttribute('id', beaconId);
                    beaconNode.setAttribute('nonce', beaconId);
                    beaconNode.classList.add('wootric');
                    setupBeacon(beaconNode);
                }
            }
        });

        return () => {
            stopWootric();
        };
    }, [wootricEnabled]);

    const setupBeacon = (script: HTMLScriptElement) => {
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://cdn.wootric.com/wootric-sdk.js';
        script.onload = handleOnLoad;
        document.body.appendChild(script);
    };

    const startWootric = () => {
        const win = window;
        if (win.wootric) {
            win.wootric('run');
        }
    };

    const stopWootric = () => {
        const win = window;
        if (win.wootric) {
            win.wootric('stop');
        }
    };

    const getDeviceOS = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera || '';
        if (/windows phone/i.test(userAgent)) {
            return 'Windows Phone';
        }

        if (/android/i.test(userAgent)) {
            return 'Android';
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 'iOS';
        }

        if (/Windows NT|Macintosh|Linux|CrOS/.test(userAgent)) {
            return 'Web';
        }

        return 'Unknown';
    };

    const setupWootricOptions = (script: HTMLScriptElement, globalValues: IGlobalValuesDataRow) => {
        const userEnabledDate = new Date(globalValues.dt_userEnabled);
        const timestamp = Math.floor(userEnabledDate.getTime() / 1000);
        // when running in development mode, or if the Database thinks it is a demo or Dev system set dev mode.
        const devMode = process.env.NODE_ENV === 'development' || globalValues.b_isDemoOrDevSystem;
        const customProps = {
            product: 'Access Maintain',
            customerName: globalValues.nv_clientName,
            userRole: globalValues.nv_UserType,
            productReportingCode: 395,
            deviceOS: getDeviceOS(),
        };

        if (script) {
            script.innerHTML = `
                window.wootricSettings = {
                  email: "${globalValues.username}",
                  created_at: ${timestamp},
                  modal_theme: "Dark",
                  account_token: "${devMode ? testAccountToken : newAccountToken}",
                  disclaimer: {
                    text: 'Learn how we handle your feedback',
                    link: 'https://www.theaccessgroup.com/en-gb/privacy-notice/',
                    link_word: 'here'
                    },
                  properties: ${JSON.stringify(customProps)}
                };
              `;
        }
    };

    const handleOnLoad = () => {
        startWootric();
    };

    return <div />;
};

export default withRouter(Wootric);
